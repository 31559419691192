@tailwind base;
@tailwind components;
@tailwind utilities;

/* Roboto Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

#__next {
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button,
button:active,
button:focus
{
  outline: none;
}

/* https://stackoverflow.com/questions/23211656/remove-ios-input-shadow */
input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
